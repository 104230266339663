import React, { ReactElement } from "react";
import Layout from "../components/layout";

const PrivacyPolicy = (): ReactElement => {
    return (
        <div className="bg-darkGray min-h-screen p-4 sm:p-8 text-offWhite space-y-4">
            <h1 className="mx-auto text-3xl text-center font-changa">
                Privacy policy
            </h1>
            <p>Privacy Policy</p>
            <p>Effective Date: March 11, 2021</p>

            <p>
                <strong>ABOUT</strong>
            </p>
            <p>
                The vision for Streamlux, LLC and its affiliates (
                <strong>“Streamlux,” “we,” “us,” </strong>and{" "}
                <strong>“our”</strong>) is to bring enhanced experiences and
                simplify complex lighting systems for all streamers. As such, we
                provide a light effect design platform that you can use, among
                other things, to create light effects, connect to streaming
                events, and control your wifi-enabled smart lights. This Privacy
                Policy is designed to help you understand how we collect, use
                and share your personal information and to assist you in
                exercising the privacy rights available to you. If you have any
                questions or concerns about our policy, or our practices with
                regards to your personal information, please contact us at{" "}
                <u>support@streamlux.com</u> or visit our help site at help.streamlux.com.
            </p>

            <p>
                <strong>SCOPE</strong>
            </p>
            <p>
                This Privacy Policy applies to personal information processed by
                us in our business, including on our website (e.g.,
                streamlux.com, and any other websites that we own or operate)
                and our desktop application (collectively, the{" "}
                <strong>“Services”</strong>).
            </p>

            <p>
                This Privacy Policy does not apply to any third-party websites,
                services or applications, even if they are accessible through
                our Services. In addition, capitalized terms not defined in this
                Privacy Policy will have the meaning set forth in our Terms of
                Service.
            </p>

            <p>
                <strong>PERSONAL INFORMATION WE COLLECT</strong>
            </p>
            <p>
                The categories of personal information we collect depend on how
                you interact with our Services.
            </p>

            <p>
                <strong>Information You Provide to Us</strong>
            </p>
            <p>
                Account Information. When you create an Account, we will collect
                personal information, such as your name and email address.
            </p>

            <p>
                Payment Information. We may sell services or merchandise through
                our Services. When you make purchases through the Services, we
                may process your payments through a third-party application,
                such as Paypal. The third-party application may collect certain
                financial information from you to process a payment on behalf of
                Streamlux, including your name, email address, address, payment
                card information, and other billing information. Streamlux does
                not store or receive your payment information, but it may store
                and receive information associated with your payment information
                (e.g., your billing details).
            </p>

            <p>
                <strong>Your Communications with Us.</strong> We collect
                personal information from you such as your name and email
                address when you request information or share feedback about our
                Services.
            </p>

            <p>
                <strong>Interactive Features. </strong>We may offer interactive
                features such as collaboration tools, tagging functionalities,
                forums, blogs, social media pages and the ability to react,
                follow, observe in real-time and comment on users’ content. We,
                and others who use our Services, may collect the information you
                submit or make available through these interactive features. Any
                content you provide on the public sections of these features
                will be considered “public” and is not subject to the privacy
                protections referenced herein. By using these interactive
                features, you assume the risk that the personal information
                provided by you may be viewed and used by third parties for
                their own purposes.
            </p>

            <p>
                <strong>
                    Customer Service, Training and Quality Assurance.
                </strong>{" "}
                If you email or otherwise interact with Streamlux’s customer
                service and support, we may collect the information you provide
                to our representatives for training or quality assurance
                purposes.
            </p>

            <p>
                <strong>Registration for Sweepstakes or Contests.</strong> We
                may run sweepstakes and contests. Contact information you
                provide may be used to reach you about the sweepstakes or
                contest and for other promotional, marketing and business
                purposes, if permitted by law. In some jurisdictions, we are
                required to publicly share information about the winners.
            </p>

            <p>
                <strong>
                    Business Development and Strategic Partnerships.
                </strong>{" "}
                We may collect personal information from individuals and third
                parties to assess and pursue potential business opportunities.
            </p>

            <p>
                <strong>Information Collected Automatically</strong>
            </p>

            <p>
                <strong>Automatic Data Collection.</strong> We may collect
                certain information automatically when you use the Services.
                This information may include your Internet protocol (IP)
                address, user settings, MAC address, cookie identifiers, mobile
                advertising and other unique identifiers, details about your
                browser, operating system or device, location information
                (including inferred location based off of your IP address),
                Internet service provider, pages that you visit before, during
                and after using the Services, information about the links you
                click, and information about how you interact with and use the
                Services.
            </p>

            <p>
                We may use a visitor’s IP address to ﬁght spam, malware and
                identity theft. With your permission, we may also collect
                information about your operating system’s installed fonts in
                connection with providing the Services to you. Combined with
                other system information, this information could be used to give
                your computer a unique ﬁngerprint/signature and enable us to
                track your usage of our Services after you log out.
            </p>

            <p>
                <strong>
                    Cookies, Pixel Tags/Web Beacons, and Analytics Information.
                </strong>{" "}
                We, as well as third parties that may provide content,
                advertising, or other functionality on the Services, may use
                cookies, pixel tags, local storage, and other technologies
                (“Technologies”) to automatically collect information through
                the Services. Technologies are essentially small data files
                placed on your device that allow us and our partners to record
                certain pieces of information whenever you visit or interact
                with our Services.
            </p>

            <ul>
                <li>
                    <strong>Cookies.</strong> Cookies are small text files
                    placed in device browsers to store their preferences. Most
                    browsers allow you to block and delete cookies. However, if
                    you do that, the Services may not work properly.
                </li>
                <li>
                    <strong>Pixel Tags/Web Beacons.</strong> A pixel tag (also
                    known as a web beacon) is a piece of code embedded in the
                    Services that collects information about engagement on the
                    Services. The use of a pixel tag allows us to record, for
                    example, that a user has visited a particular web page or
                    clicked on a particular advertisement. We may also include
                    web beacons in e-mails to understand whether messages have
                    been opened, acted on, or forwarded.
                </li>
            </ul>

            <p>
                <strong>Analytics.</strong> We may also use Google Analytics and
                other service providers to collect and process analytics
                information on our Services. For more information about how
                Google uses data, please visit
                www.google.com/policies/privacy/partners/. You can opt out of
                Google Analytics’ collection and processing of data generated by
                your use of our website by going to
                http://tools.google.com/dlpage/gaoptout.
            </p>

            <p>
                <strong>Information from Other Sources</strong>
            </p>

            <p>
                <strong>Third Party Services and Organizations.</strong> We may
                obtain information about you from other sources, including from
                third party services and organizations. For example, if you
                access our Services through a third-party service, we may
                collect information about you from that third-party service that
                you have made available via your privacy settings.
            </p>

            <p>
                <strong>HOW WE USE YOUR INFORMATION</strong>
            </p>

            <p>
                We use your personal information for a variety of purposes
                centered around improving the products that we provide,
                including:
            </p>

            <p>
                <strong>
                    To Provide the Services or Information Requested, such as:
                </strong>
            </p>

            <ul>
                <li>
                    Fulfilling our contract with you or the organization on
                    whose behalf you use the Services;
                </li>
                <li>Managing your information and Account;</li>
                <li>Responding to questions, comments, and other requests;</li>
                <li>
                    Processing payment card and/or other financial information
                    to facilitate your use of the Services;
                </li>
                <li>
                    Providing access to certain areas, functionalities, and
                    features of our Services;
                </li>
                <li>
                    Answering requests for customer or technical support; and
                </li>
                <li>Allowing you to register for events.</li>
            </ul>

            <p>
                <strong>Administrative Purposes, such as:</strong>
            </p>

            <ul>
                <li>
                    Pursuing legitimate interests, such as direct marketing,
                    research and development (including marketing research),
                    network and information security, and fraud prevention;
                </li>
                <li>Measuring interest and engagement in our Services;</li>
                <li>Improving the Services;</li>
                <li>Developing new products and services;</li>
                <li>Ensuring internal quality control and safety;</li>
                <li>Authenticating and verifying individual identities;</li>
                <li>Carrying out audits;</li>
                <li>
                    Communicating with you about your account, activities on our
                    Services and Privacy Policy changes;
                </li>
                <li>
                    Preventing and prosecuting potentially prohibited or illegal
                    activities;
                </li>
                <li>Enforcing our agreements; and</li>
                <li>Complying with our legal obligations.</li>
            </ul>

            <p>
                <strong>Marketing Our Products and Services. </strong>We may use
                personal information to tailor and provide you with content and
                advertisements. We may provide you with these materials as
                permitted by applicable law
            </p>

            <p>
                If you have any questions about our marketing practices or if
                you would like to opt out of the use of your personal
                information for marketing purposes, you may contact us at any
                time as set forth below.
            </p>

            <p>
                <strong>Consent.</strong> We may use personal information for
                other purposes that are clearly disclosed to you at the time you
                provide personal information or with your consent.
            </p>

            <p>
                <strong>De-identified and Aggregated Information Use. </strong>
                We may use personal information and other data about you to
                create de-identified and/or aggregated information, such as
                de-identified demographic information, de-identified location
                information, information about the device from which you access
                our Services, or other analyses we create. De-identified and/or
                aggregated information is not personal information, and we may
                use and disclose such information in a number of ways, including
                research, industry analysis, analytics, and any other legally
                permissible purposes.
            </p>

            <p>
                <strong>Share Content with Friends or Colleagues.</strong> Our
                Services may offer various tools and functionalities that allow
                you to share content. For example, we may allow you to provide
                information about your friends through our referral services.
                Our referral services may allow you to forward or share certain
                content with a friend or colleague, such as an email inviting
                your friend to use our Services.
            </p>

            <p>
                <strong>How We Use Automatic Collection Technologies. </strong>
                We, as well as third parties that may provide content,
                advertising, or other functionality on the Services, may use
                cookies, pixel tags, local storage, and other technologies to
                automatically collect information through the Services. Our uses
                of these Technologies fall into the following general
                categories:
            </p>

            <ul>
                <li>
                    <strong>Operationally Necessary.</strong> This includes
                    Technologies that allow you access to our Services,
                    applications, and tools that are required to identify
                    irregular site behavior, prevent fraudulent activity and
                    improve security or that allow you to make use of our
                    functionality;
                </li>
                <li>
                    <strong>Performance Related.</strong> We may use
                    Technologies to assess the performance of our Services,
                    including as part of our analytic practices to help us
                    understand how our visitors use the Services;
                </li>
                <li>
                    <strong>Functionality Related.</strong> We may use
                    Technologies that allow us to offer you enhanced
                    functionality when accessing or using our Services. This may
                    include identifying you when you sign into our Services or
                    keeping track of your specific preferences, interests, or
                    past items viewed;
                </li>
                <li>
                    <strong>Advertising or Targeting Related.</strong> We may
                    use first party or third-party Technologies to deliver
                    content, including ads relevant to your interests, on our
                    Services or on third party sites.
                </li>
            </ul>

            <p>
                Please note that this Privacy Policy covers only our use of
                Technologies and does not govern the use of Technologies by any
                third parties.
            </p>

            <p>
                <strong>DISCLOSING YOUR INFORMATION TO THIRD PARTIES</strong>
            </p>
            <p>
                We may share any personal information we collect with the
                following categories of third parties:
            </p>

            <p>
                <strong>Service Providers. </strong>We may share any personal
                information we collect about you with our third-party service
                providers. The categories of service providers to whom we
                entrust personal information include service providers for: (i)
                the provision of the Services; (ii) the provision of
                information, products, and other services you have requested;
                (iii) marketing and advertising; (iv) payment and transaction
                processing; (v) customer service activities; and (vi) the
                provision of IT and related services.
            </p>

            <p>
                <strong>Third-Party Platforms and Services.</strong> We will
                share your personal information with third-party platforms
                and/or services if you have expressly consented or requested
                that we do so. Please note we do not endorse, screen or approve,
                and are not responsible for, the practices or conduct of such
                third-party services.
            </p>

            <p>
                <strong>Business Partners. </strong>We may provide personal
                information to business partners to provide you with a product
                or service you have requested. We may also provide personal
                information to business partners with whom we jointly offer
                products or services.
            </p>

            <p>
                <strong>Affiliates.</strong> We may share personal information
                with our affiliated entities.
            </p>

            <p>
                <strong>Disclosures to Protect Us or Others. </strong>We may
                access, preserve, and disclose any information we have
                associated with you if we believe doing so is required or
                appropriate to: (i) comply with law enforcement or national
                security requests and legal process, such as a court order or
                subpoena; (ii) protect your, our or others’ rights, property, or
                safety; (iii) to enforce Figma’s policies and contracts; (iv) to
                collect amounts owed to us; (v) when we believe disclosure is
                necessary or appropriate to prevent financial loss or in
                connection with an investigation or prosecution of suspected or
                actual illegal activity; or (vi) if we, in good faith, believe
                that disclosure is otherwise necessary or advisable.
            </p>

            <p>
                <strong>
                    Disclosure in the Event of Merger, Sale, or Other Asset
                    Transfer.
                </strong>{" "}
                If we are involved in a merger, acquisition, financing due
                diligence, reorganization, bankruptcy, receivership, purchase or
                sale of assets, or transition of service to another provider,
                then your information may be sold or transferred as part of such
                a transaction, as permitted by law and/or contract.
            </p>

            <p>
                <strong>YOUR CHOICES</strong>
            </p>

            <p>
                <strong>General.</strong> You may have the right to opt out of
                certain uses of your personal information.
            </p>

            <p>
                <strong>Email. </strong>If you receive an unwanted email from
                us, you can use the unsubscribe link found at the bottom of the
                email to opt out of receiving future emails. Note that you will
                continue to receive transaction-related emails regarding
                products or Services you have requested. We may also send you
                certain non-promotional communications regarding us and our
                Services, and you will not be able to opt out of those
                communications (e.g., communications regarding the Services or
                updates to our Terms of Service or this Privacy Policy).
            </p>

            <p>
                <strong>Desktop Devices.</strong> We may send you push
                notifications through our desktop application. You may at any
                time opt-out from receiving these types of communications by
                changing the settings on your device.
            </p>

            <p>
                <strong>DNT</strong>. Most web browsers and some mobile
                operating systems and mobile applications include a Do-Not-Track
                (“DNT”) feature or setting you can activate to signal your
                privacy preference not to have data about your online browsing
                activities monitored and collected. No uniform technology
                standard for recognizing and implementing DNT signals has been
                finalized. As such, we do not currently respond to DNT browser
                signals or any other mechanism that automatically communicates
                your choice not to be tracked online.
            </p>

            <p>
                <strong>Cookies and Interest-Based Advertising.</strong> You may
                stop or restrict the placement of Technologies on your device or
                remove them by adjusting your preferences as your browser or
                device permits.{" "}
            </p>

            <p>
                <strong>YOUR PRIVACY RIGHTS</strong>
            </p>
            <p>
                For purposes of the California Consumer Privacy Act, we do not
                “sell” your personal information.
            </p>

            <p>
                In accordance with applicable law, you may have the right to:
                (i) request confirmation of whether we are processing your
                personal information; (ii) obtain access to or a copy of your
                personal information; (iii) receive an electronic copy of
                personal information that you have provided to us, or ask us to
                send that information to another company (the “right of data
                portability”); (iv) object to or restrict our uses of your
                personal information; (v) seek correction or amendment of
                inaccurate, untrue, incomplete, or improperly processed personal
                information; (vi) withdraw your consent and (vii) request
                erasure of personal information held about you by us, subject to
                certain exceptions prescribed by law. If you would like to
                exercise any of these rights, please contact us as set forth
                below.
            </p>

            <p>
                We will process such requests in accordance with applicable
                laws. To protect your privacy, we will take steps to verify your
                identity before fulfilling your request.
            </p>

            <p>
                If you are a California resident, you have the right not to
                receive discriminatory treatment by Streamlux for the exercise
                of your rights conferred by the California Consumer Privacy Act.
            </p>

            <p>
                <strong>DATA RETENTION</strong>
            </p>
            <p>
                We store the personal information we receive as described in
                this Privacy Policy for as long as you use our Services or as
                necessary to fulfill the purpose(s) for which it was collected,
                provide our Services, resolve disputes, establish legal
                defenses, conduct audits, pursue legitimate business purposes,
                enforce our agreements, and comply with applicable laws.
            </p>

            <p>
                <strong>SECURITY OF YOUR INFORMATION</strong>
            </p>
            <p>
                We take steps to ensure that your information is treated
                securely and in accordance with this Privacy Policy.
                Unfortunately, no system is 100% secure, and we cannot ensure or
                warrant the security of any information you provide to us. To
                the fullest extent permitted by applicable laws, we do not
                accept liability for unauthorized disclosure.
            </p>

            <p>
                By using the Services or providing personal information to us,
                you agree that we may communicate with you electronically
                regarding security, privacy, and administrative issues relating
                to your use of the Services. If we learn of a security system’s
                breach, we may attempt to notify you electronically by posting a
                notice on the Services, by sending an email to you.
            </p>

            <p>
                <strong>THIRD PARTY WEBSITES/APPLICATIONS</strong>
            </p>
            <p>
                The Services may contain links to other websites/applications
                and other websites/applications may reference or link to our
                Services. These third-party services are not controlled by us.
                We encourage our users to read the privacy policies of each
                website and application with which they interact. We do not
                endorse, screen or approve, and are not responsible for, the
                privacy practices or content of such other websites or
                applications. Visiting these other websites or applications is
                at your own risk.
            </p>

            <p>
                <strong>CHILDREN’S INFORMATION</strong>
            </p>
            <p>
                The Services are not directed to children under 13 (or other age
                as required by local law), and we do not knowingly collect
                personal information from children. If you learn that your child
                has provided us with personal information without your consent,
                you may contact us as set forth below. If we learn that we have
                collected a child’s personal information in violation of
                applicable law, we will promptly take steps to delete such
                information and terminate the child’s account.
            </p>

            <p>
                <strong>SUPERVISORY AUTHORITY</strong>
            </p>
            <p>
                If you are located in the European Economic Area or the United
                Kingdom, you have the right to lodge a complaint with a
                supervisory authority if you believe our processing of your
                personal information violates applicable law.
            </p>

            <p>
                <strong>CHANGES TO OUR PRIVACY POLICY</strong>
            </p>
            <p>
                We may revise this Privacy Policy from time to time at our sole
                discretion. If there are any material changes to this Privacy
                Policy, we will notify you as required by applicable law. You
                understand and agree that you will be deemed to have accepted
                the updated Privacy Policy if you continue to use the Services
                after the new Privacy Policy takes effect.
            </p>

            <p>
                <strong>CONTACT US</strong>
            </p>
            <p>
                If you have any questions about our privacy practices or this
                Privacy Policy, or if you wish to submit a request to exercise
                your rights as detailed in this Privacy Policy, please contact
                us at:
            </p>

            <p>Streamlux, LLC.</p>
            <p>
                Email: <u>support@streamlux.com</u>
            </p>
        </div>
    );
};

export default PrivacyPolicy;
